<template>
  <div class="classifyList">
    <div class="classList">
      <div
        @click="
          toDetail(key);
          $emit('getcheckindex', key);
        "
        :class="{ checkSty: checkindex == key }"
        v-for="(item, key) in toptype"
        :key="key"
        class="classItem"
      >
        <div>
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getTopType } from "../api/path";
export default {
  props: {
    checkindex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      toptype: [],
    };
  },

  created() {
    this.getTopType();
  },
  methods: {
    // 获取分类数据
    getTopType() {
      let params = {
        typeVal: 3,
      };
      getTopType(params)
        .then((res) => {
          this.toptype = res.data;
        })
        .catch((err) => {
          console.log("数据获取失败", err);
        });
    },
    toDetail(key) {
      this.$router.replace({
        path: "/onlineClassRoom",
        query: { mid: 12, checkindex: key },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.classifyList {
  background-color: #d81722;
  font-size: 3vw;
  color: #fff;
  width: 100%;
  height: 6vh;
  .classList {
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;
    :not(:nth-last-child(1)) {
      border-right: 1px solid #fff;
    }
    .classItem {
      width: 33%;
      height: 60%;

      display: flex;
      justify-content: center;
      align-items: center;
      div {
        height: 6vh;
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.checkSty {
  div {
    margin-top: 30px;
    background-color: #bf545a;
    display: flex;
    align-items: flex-start !important;
    span {
      margin-top: 10px;
    }
  }
}
</style>