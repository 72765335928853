import { render, staticRenderFns } from "./leveThreePageZXKT.vue?vue&type=template&id=20366752&scoped=true&"
import script from "./leveThreePageZXKT.vue?vue&type=script&lang=js&"
export * from "./leveThreePageZXKT.vue?vue&type=script&lang=js&"
import style0 from "./leveThreePageZXKT.vue?vue&type=style&index=0&id=20366752&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20366752",
  null
  
)

export default component.exports