<template>
  <div class="chair" :style="{height:bgHeight}">
    <!-- 专家讲堂 -->
    <swipeTop :top_img="top_img" :ZHtitle="ZHtitle"></swipeTop>
    <!-- 搜素框 -->
    <seachVue
      :isDisabled="isDisabled"
      :placeholderText="placeholderText"
      @searchName="searchName"
      @clear="clear"
    ></seachVue>
    <!-- 分类 -->
    <!-- <classifyListOnline :checkindex="checkindex"></classifyListOnline> -->
    <classifyList :checkindex="checkindex" :toptype="toptype"></classifyList>
    <div class="cardBox">
      <div @click="video(item)" class="cardItem" v-for="(item) in priductList">
        <van-row>
          <van-col span="6"></van-col>
          <van-col span="18" class="videoIntroduce">
            <div class="top">
              <van-row gutter="5">
                <van-col style="text-align: end" span="6">直播主题：</van-col>
                <van-col span="17">{{ item.productName }}</van-col>
              </van-row>
              <van-row gutter="5" class="speaker">
                <van-col style="text-align: end" span="6">主讲人：</van-col>
                <van-col span="16">{{ item.description }}</van-col>
              </van-row>
            </div>
            <van-row gutter="5" class="department">
              <van-col style="text-align: end" span="6">目标科室：</van-col>
              <van-col span="17">
                <van-tag round type="danger" v-for="(item, index) in item.characteristic">
                  {{ item }}
                </van-tag>
              </van-col>
            </van-row>
          </van-col>
        </van-row>

      </div>
    </div>
  </div>
</template>

<script>
import swipeTop from "@/components/swipeTop.vue";
import seachVue from "@/components/seachVue.vue";
import classifyListOnline from "@/components/classifyListOnline.vue";
import classifyList from "../../components/classifyList.vue";
import { genMenuInfo, searchBYKey } from "@/api/path";
export default {
  components: { swipeTop, seachVue, classifyListOnline, classifyList },
  data() {
    return {
      top_img: "",
      img: require("@/assets/img/video.png"),
      checkindex: Number(this.$route.query.menuId),
      menuId: this.$route.query.menuId,
      ZHtitle: "在线课堂",
      toptype: [],
      ParentId: 0,
      department: [], // 目标科室
      priductList: [],
      isDisabled: false,
      placeholderText: "输入主题关键字或主讲人搜索",
      bgHeight:"",
    };
  },
  watch: {
    $route: function (newQuestion, oldQuestion) {
      this.menuId = newQuestion.query.menuId;
      this.checkindex = Number(newQuestion.query.menuId);
      this.getAllMenu();
    },
  },
  mounted() {
    // 监听移动端返回
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.backButton, false);
    }
    this.bgHeight = document.documentElement.clientHeight + "px";
  },
  created() {
    this.getAllMenu();
  },
  methods: {
    // 查询
    searchName(value1) {
      let params = {
        search: value1,
      };
      searchBYKey(this.menuId, params).then((res) => {
        res.data.productList.forEach(item => {
          item.characteristic = item.characteristic.split('、')
        })
        this.priductList = res.data.productList;
      });
    },
    // 清除搜索框
    clear() {
      this.getAllMenu();
    },
    video(item) {
      location.href = item.fileUrl;
    },
    getAllMenu() {
      genMenuInfo(this.menuId)
        .then((res) => {
          this.priductList = res.data.productList;
          this.toptype = res.data.classificationMessageAlways; //获取标签分类
          res.data.productList.forEach(item => {
            item.characteristic = item.characteristic.split('、')
          })
          console.log("res.data.productList",res.data.productList)
          // console.log("res.data.productList",res.data.productList)
          // let department = res.data.productList[0].characteristic;
          // console.log("department",department)
          // this.department = department.split("、");
          this.ParentId = res.data.parentId;
          this.top_img = res.data.background; // 获取头部背景图片
        })
        .catch((err) => {
          console.log(err);
        });
    },
    backButton() {
      this.$router.replace({
        path: "/secondaryPages",
        query: { mid: this.ParentId },
      });
    },
  },
  destroyed() {
    // 销毁页面返回事件
    window.removeEventListener("popstate", this.backButton, false);
  },
};
</script>

<style lang="scss" scoped>
.chair {
  height: 100vh;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    display: none;
  }

  .cardBox {
    flex: 1;
    height: 51%;
    overflow: scroll;
    background-color: #bf545a;
    font-family: 'FZZZH',serif;
    .cardItem{
      width: 100%;
      height: 222px;
      font-size: 0.5rem;
      color: white;

      background-image: url("../../assets/img/videoBg.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      margin-bottom: 10px;
      .videoIntroduce{
        height: 222px;
        overflow: scroll;
        padding: 10px 0;
        .top{
          min-height: 148px;
        }
        .department{
          .van-tag{
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.van-col{
  margin-bottom: 8px;
}
</style>
